import {BrowserRouter, Route,RouterProvider,ScrollRestoration,createBrowserRouter,createRoutesFromElements, useRoutes,} from "react-router-dom";
import Home from "./Pages/Home";
import MainNavigation from "./Pages/MainNavigation";
import ErrorPage from "./Pages/Error";
import SareesByPrice from "./Pages/SareesByPrice";
import SareeDetail  from "./Pages/SareeDetail";
import SIgnUp from "./Pages/SignUp";
import SignIn from "./Pages/Signin";
import SareesByCategory from "./Pages/SareesByCategory";
import PageNavigation from "./Pages/PageNavigation";
import Cart from "./Pages/Cart";
import RequireAuth, { RequireAuthAdmin } from "./components/shared/auth/RequireAuth";
import CustomerDetails from "./Pages/CustomerDetails";
import DashboardLayout from "./Pages/admin/DashboardLayout";
import HomeAdmin from "./Pages/admin/Home";
import ProductsAdmin from "./Pages/admin/Products";
import EditProduct from "./Pages/admin/EditProduct";
import NewProduct from "./Pages/admin/AddProduct";
import ViewProductPage from "./Pages/admin/ViewProductPage";
import UploadImagesPage from "./Pages/admin/UploadImagesPage";
import Checkout from "./Pages/Checkout";
import Wishlist from "./Pages/Wishlist";
import OrdersAdmin from "./Pages/admin/Orders";
import SareesBySubCategory from "./Pages/SareesBySubCategory";
import MyOrders from "./Pages/MyOrders";
import CancelledOrders from "./Pages/admin/CancelledOrders";
import ClosedOrders from "./Pages/admin/ClosedOrders";
import Privacy from "./Pages/footerpages/Privacy";
import Return from "./Pages/footerpages/Return";
import ExploreVideo from "./Pages/ExploreVideo";
import About from "./Pages/footerpages/About";
import Stores from "./Pages/footerpages/Stores";
import ReturnTerms from "./Pages/footerpages/TermsReturn";
import ShippingPolicy from "./Pages/footerpages/ShippingPolicy";
import Customers from "./Pages/admin/Customers";
import Payment from "./components/shared/checkout/Payment";
import FAQ from "./Pages/footerpages/FAQ";
import FranchiseEnquiry from "./Pages/footerpages/FranchiseEnquiry";
import ForgotPwd from "./Pages/ForgotPwd";
import { ScrollTop } from "./components/shared/RootLayout/ScrollToTop";
import CateoriesPageAdmin from "./Pages/admin/Cateories";


const Rout = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<MainNavigation />} errorElement={<ErrorPage />}>
        <Route index element={<Home />} />
      </Route>
      <Route element={<PageNavigation />}>
      <Route element={<ScrollTop />}>
        <Route path="shopbyprice/:price" element={<SareesByPrice />} />
        <Route path="category/:catId" element={<SareesByCategory />} />
        <Route path="subcategory/:subcatId" element={<SareesBySubCategory />} />
        <Route path="newcollection/:id" element={<SareeDetail />} />
        <Route path="privacy-policy" element={<Privacy />} />
        <Route path="return-policy" element={<Return />} />
        <Route path="terms-and-conditions" element={<ReturnTerms />} />
        <Route path="shipping-policy" element={<ShippingPolicy />} />
        <Route path="help-faq" element={<FAQ />} />
        <Route path="enquiry" element={<FranchiseEnquiry />} />
        <Route path="stores" element={<Stores />} />
        <Route path="about-us" element={<About />} />
        <Route path="signup" element={<SIgnUp />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="forgotpwd" element={<ForgotPwd />} />
        <Route path="explore" element={<ExploreVideo />} />
        <Route path="cart" element={<Cart />} />
        <Route path="wishlist" element={<Wishlist />} />
        <Route path="saree/:id" element={<SareeDetail />}/>
        <Route element={<RequireAuth/>}>
        <Route path="userdetails" element={<CustomerDetails/>}/>
        <Route path="checkout" element={<Checkout/>}/>
        <Route path="myorders" element={<MyOrders/>}/>
        <Route path="orderres/:id" element={<Payment/>}/>
        </Route>
      </Route>
      </Route>
      <Route element={<RequireAuthAdmin/>}>
      <Route path="admin" element={<DashboardLayout/>}>
          <Route index element={<HomeAdmin/>}/>
          <Route path="dashboard/products/:catId" element={<ProductsAdmin/>}/>
          <Route path="dashboard/orders/active" element={<OrdersAdmin/>}/>
          <Route path="dashboard/customers" element={<Customers/>}/>
          <Route path="dashboard/orders/cancelled" element={<CancelledOrders/>}/>
          <Route path="dashboard/orders/closed" element={<ClosedOrders/>}/>
          <Route path="dashboard/newproduct" element={<NewProduct/>}/>
          <Route path="dashboard/categories" element={<CateoriesPageAdmin/>}/>
          <Route path="dashboard/edit/:id" element={<EditProduct/>}/>
          <Route path="dashboard/uploadimages/:id/:catId" element={<UploadImagesPage/>}/>
          <Route path="dashboard/view/:id" element={<ViewProductPage/>}/>
      </Route>
      </Route>
    </Route>
  )
);



/* const browserRouter = createBrowserRouter(routes);

const App = () => {
  return <RouterProvider router={browserRouter} />;
}; 
const App = () => {
  return <RouterProvider router={Rout} ><ScrollRestoration /></RouterProvider>

}; */


const App = () => {
  return <RouterProvider router={Rout} />;
}; 


export default App;

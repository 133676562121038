import { DataTable } from "../../components/shared/admin/components/Table/data-table";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { useGetAllCategoryQuery } from "../../redux/slice/CategoryApiSlice";
import { useGetCancelledOrdersAQuery } from "../../redux/slice/admin/ACreateOrderApiSlice";
import { orderColumnsCancelled } from "../../components/shared/admin/components/OrdersTable/OrderColumnsCancelled";



const CancelledOrders = () => {
  const { isSuccess, isError, isLoading, data: getCancelledorders } = useGetCancelledOrdersAQuery("ordersList", { pollingInterval: 6000, refetchOnFocus: true, refetchOnMountOrArgChange: true, })

  let content
  if (isLoading) {
    content = (
        <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
    );
}
  if (isError) content = <p>{getCancelledorders?.result?.message}</p>
  if (!getCancelledorders?.result?.length) {
    content = <p className="p-4 text-center text-lg font-semibold w-full">
      NO CANCELLED ORDERS YET
    </p>
  }
  if (isSuccess && getCancelledorders?.result?.length > 0) {
    content = <>
      <div className="flex justify-between p-4">
        <h5 className='h5-bold'>CANCELLED ORDERS</h5>
      </div>
      <div className=" p-4 rounded-md border-2 md:overflow-hidden shadow-lg bg-green-50">
        {getCancelledorders?.result?.length > 0 && <DataTable data={getCancelledorders?.result} columns={orderColumnsCancelled} />}
      </div>
    </>
  }
  //md:max-w-[700px]  lg:max-w-[800px] xl:max-w-[900px] 2xl:max-w-[1200px]
  return (<section className="md:ml-52">
    <main className=" border-2  bg-red-50  border-gray-500 md:mx-8 md:my-12 md:p-4">
      {content}
    </main>
  </section>)
}

export default CancelledOrders


import { Link } from "react-router-dom"
import { Button } from "../../ui/button"



// const Download = () => {
//   return (
//     <div className="flex justify-center w-[100%] bgcolorgold  pt-2  ">
//  <img
//     src="/assets/mobile-app.jpg"
//     alt="mobile"
//     width={450}
//     height={250}
//     className="h-[400px] w-full"/>
//   {/* 
//     <div className="absolute top-10 md:left-15  bg-red-100  text-white left-5 md:w-[50%] flex flex-col gap-3">
//     <Image
//     src=""
//     alt="explore"
//     width={450}
//     height={250}
//     className="w-full h-[300px] "/>
//         <h1 className="italic text-2xl font-bold text-center ">Shop via video call </h1>
//         <p>Indulge in a world of refined elegance with our handpicked collection. Ensuring you experience the pinnacle of luxury. Elevate your style and embrace sophistication with every ensemble. Discover timeless beauty with us.</p>
//         <Button size="lg" asChild className='button bg-red-300 w-full text-center sm:w-fit'><Link href="/explore">
//           Explore Now
//         </Link></Button>
//     </div> */}
// </div>
//   )
// }


const Download = () => {
  return (
    <div className="flex justify-center w-full md:gap-10 gap-2 items-center">
  
 <img
    src="/images/Download.jpg"
    alt="mobile"
    className=" w-full rounded-lg"/>
    {/* <div className="absolute top-10 md:left-15  bg-red-100  text-white left-5 md:w-[50%] flex flex-col gap-3"> 
        <Button size="lg" asChild className='bg-none h-[50px]'><img src="/assets/payments/playstore.webp"alt="playstore"/></Button>*/}
        <div className="flex flex-col gap-5 absolute right-[25%]">
        <Link to="/"><img src="/assets/payments/playstore.jpeg"alt="playstore" className="h-12 w-44 rounded-lg"/></Link>
        <Link to="/"><img src="/assets/payments/apple.png" alt="applestore" className="h-12 w-44 rounded-lg"/></Link>
        </div>
</div>
  )
}

export default Download
import Header from "../components/shared/SubLayout/Header";
import { Outlet } from "react-router-dom";
import Footer from "../components/shared/RootLayout/Footer";


const PageNavigation = () => {
  return (
    <div className="flex flex-col ">
      <Header />
      <Outlet />
      <Footer/>
    </div>
  );
};

export default PageNavigation;

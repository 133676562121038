import Weaves from '../components/shared/Home/Weaves'
import Video from '../components/shared/Home/Video'
import ShopByPrice from '../components/shared/Home/ShopByPrice'
import Explore from '../components/shared/Home/Explore'
import NewCollection from '../components/shared/Home/NewCollection'
import Download from '../components/shared/Home/Download'
import MyChatBot from '../components/shared/Home/chatbot.tsx/Chatbot'


const Home = () => {
  return (
    <>
    <Video/>
    <Weaves/>
    <ShopByPrice/>
    <Explore/>
    <NewCollection />
    <MyChatBot/>
    </>
  )
}


export default Home
import { Card } from "../../ui/card";
import { Link } from "react-router-dom";


const Weaves = () => {
  return (
    <section className=" wrapper-weaves font-display mt-4 ">
      <div className="flex justify-center py-1">
        {/* <h5 className="h3-bold ">Our Weaves</h5> */}
        <h5 className="text-4xl ">Our Weaves</h5>
      </div>
    
      <div className="wrapper-weaves grid grid-cols-1 gap-1">
      <div className="grid grid-cols-1  gap-1 lg:grid-cols-2 ">
        <div className="w-full  max-sm:h-[250px]  relative">
        <Link to={`/category/1`} >
          <img
            src="/assets/categories/PureSilkSaree.jpg"
            alt="PURE SILK SAREES"
            className="w-full h-full rounded-lg max-sm:hidden"
          />
          <img
            src="/assets/categories/PureSilkSareeM.jpg"
            alt="PURE SILK SAREES"
            className="w-full h-full rounded-lg max-sm:block hidden"
          /></Link>
        {/*   <p className="absolute bottom-[25%] left-[10%] text-white text-lg font-semibold z-20" style={{ letterSpacing: '2px' }}>PURE SILK SAREES</p>
          <Link to={`/category/1`} className="absolute bottom-[10%] left-[10%] bg-white p-2 rounded-md font-bold z-20">SHOP NOW</Link>
        */} </div>
        <div className="lg:flex lg:flex-col hidden gap-1">
        <div className="w-full  relative">
        <Link to={`/category/2`}>
          <img
            src="/assets/categories/BanarasSaree.jpg"
            alt="BANARAS SAREES"
            height={200}
            className="w-full h-full rounded-lg"
          /></Link>
        {/*   <p className="absolute lg:bottom-[35%] bottom-[25%] left-[10%] text-white text-lg font-semibold z-20" style={{ letterSpacing: '2px' }}>BANARAS SAREES</p>
          <Link to={`/category/2`} className="absolute bottom-[10%] left-[10%] bg-white p-2 rounded-md font-bold z-20">SHOP NOW</Link>
         */}</div>
        <div className="w-full  relative">
        <Link to={`/category/3`} >
          <img
            src="/assets/categories/CottonSaree.jpg"
            alt="COTTON SAREES"
            height={200}
            className="w-full h-full rounded-lg "
          /></Link>
        {/*   <p className="absolute lg:bottom-[35%]  bottom-[25%] left-[10%] text-white text-lg font-semibold z-20" style={{ letterSpacing: '2px' }}>COTTON SAREES</p>
          <Link to={`/category/3`} className="absolute bottom-[10%] left-[10%] bg-white p-2 rounded-md font-bold z-20">SHOP NOW</Link>
        */} </div>
        </div>
        <div className="lg:hidden w-full max-sm:h-[200px] h-96 relative">
        <Link to={`/category/2`}>
          <img
            src="/assets/categories/BanarasSaree.jpg"
            alt="BANARAS SAREES"
            height={200}
            className="w-full h-full rounded-lg max-sm:hidden"
          />
          <img
            src="/assets/categories/BanarasSareeM.jpg"
            alt="BANARAS SAREES"
            height={200}
            className="w-full h-full rounded-lg max-sm:block hidden"
          />
          
          </Link>
          {/* <p className="absolute lg:bottom-[35%] bottom-[25%] left-[10%] text-white text-lg font-semibold z-20" style={{ letterSpacing: '2px' }}>BANARAS SAREES</p>
          <Link to={`/category/2`} className="absolute bottom-[10%] left-[10%] bg-white p-2 rounded-md font-bold z-20">SHOP NOW</Link>
         */}</div>
        <div className="lg:hidden w-full max-sm:h-[200px] h-96 relative">
        <Link to={`/category/3`}>
          <img
            src="/assets/categories/CottonSaree.jpg"
            alt="COTTON SAREES"
            className="w-full h-full rounded-lg max-sm:hidden"
          />
          <img
            src="/assets/categories/CottonSareeM.jpg"
            alt="BANARAS SAREES"
            className="w-full h-full rounded-lg max-sm:block hidden"
          />
          </Link>
          {/* <p className="absolute lg:bottom-[35%]  bottom-[25%] left-[10%] text-white text-lg font-semibold z-20" style={{ letterSpacing: '2px' }}>COTTON SAREES</p>
          <Link to={`/category/3`} className="absolute bottom-[10%] left-[10%] bg-white p-2 rounded-md font-bold z-20">SHOP NOW</Link> */}
        </div>
      </div>
      <div className="w-full  max-sm:h-[250px] relative ">
      <Link to={`/category/4`}>
          <img
            src="/assets/categories/FancySaree.jpg"
            alt="FANCY SAREES"
            className="w-full h-full rounded-lg max-md:hidden"
          />
          <img
            src="/assets/categories/FancySareeM.jpg"
            alt="FANCY SAREES"
            className="w-full h-full rounded-lg max-sm:hidden md:hidden"
          />
           <img
            src="/assets/categories/FancySareeM.jpg"
            alt="BANARAS SAREES"
            className="w-full h-full rounded-lg max-sm:block hidden"
          />
          </Link>
         {/*  <p className="absolute bottom-[25%] left-[10%] text-white text-lg font-semibold z-20" style={{ letterSpacing: '2px' }}>FANCY SAREES</p>
          <Link to={`/category/4`} className="absolute bottom-[10%] left-[10%] bg-white p-2 rounded-md font-bold z-20">SHOP NOW</Link>
        */} </div>
      </div>
      
     
    </section>
  );
};


export default Weaves
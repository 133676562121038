import React from "react";
import ChatBot from "react-chatbotify";
import "react-chatbotify/dist/react-chatbotify.css";
import { useChatbotEnquiryEmailMutation } from "../../../../redux/slice/admin/ADashboardApiSlice";

interface Form {
  name?: string;
  mobile?: string;
  email?: string;
  choice?: string[];
  message?: string;
}
const MyChatBot = () => {
  const [form, setForm] = React.useState<Form>({});
  const [chatbotEnquiryEmail, { data }] = useChatbotEnquiryEmailMutation();
  console.log(data);

  const defaultOptions = {
    chatHistory: { storageKey: "example_advanced_form" },
    tooltip: { mode: "OPEN" },
    chatButton: { icon: "/assets/logo/Chat.png" },
    header: {
      title: (
        <h3
          style={{ cursor: "pointer", margin: 0 }}
        >
          Chat With Us
        </h3>
      ),
    },
    footer: {
      text: (
        <div>
         
        </div>
      ),
    },
    notification: {
      disabled: false,
      defaultToggledOn: true,
      volume: 0.2,
    },
  };

  const flow = {
    start: {
      message: "Hello there! What is your name?",
      function: (params: { userInput: any }) =>
        setForm({ ...form, name: params.userInput }),
      path: "ask_mobile",
    },
    ask_mobile: {
      message: (params: { userInput: any }) =>
        `Nice to meet you ${params.userInput}, what is your Mobile Number?`,
      function: (params: { userInput: any }) =>
        setForm({ ...form, mobile: params.userInput }),
      path: async (params: {
        userInput: any;
        injectMessage: (arg0: string) => any;
      }) => {
        if (
          isNaN(Number(params.userInput)) ||
          !/^\d{10}$/.test(params.userInput)
        ) {
          await params.injectMessage("Mobile Number need to be 10 digits");
          return;
        }
        return "ask_email";
      },
    },
    ask_email: {
      message: "Enter your email address",
      function: (params: { userInput: any }) =>
        setForm({ ...form, email: params.userInput }),
      path: async (params: {
        userInput: any;
        injectMessage: (arg0: string) => any;
      }) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(params.userInput)) {
          await params.injectMessage("Please enter a valid email address");
          return;
        }
        return "ask_choice";
      },
    },
    ask_choice: {
      message: "Enquiry related to, Select at least 1 and at most 2:",
      checkboxes: {
        items: [
          "Products",
          "Cancellation",
          "orders",
          "Return",
          "Store",
          "Others",
        ],
        min: 1,
        max: 2,
      },
      chatDisabled: true,
      function: (params: { userInput: any }) =>
        setForm({ ...form, choice: params.userInput }),
      path: "ask_message",
    },
    ask_message: {
      message: "Enter the Message related to query",
      function: (params: { userInput: any }) =>{
        setForm({ ...form, message: params.userInput });
      },
      path: "end",
    },
   /*  end: {
      message:"Thank you for your interest, we will get back to you shortly!",
      options: ["New Chat"],
      chatDisabled: true,
      path: "start",
    }, */
    end: {
        message: async() => {
            await chatbotEnquiryEmail(form); 
            return `Thank you for your interest, we will get back to you shortly!`
        },
      options: ["New Chat"],
      chatDisabled: true,
      path: "start",
    },
  };
  return (
    <>
      <ChatBot options={defaultOptions} flow={flow} />
    </>
  );
};

export default MyChatBot;

import { configureStore } from '@reduxjs/toolkit'
import counterCartReducer from "./features/cartSlice"
import counterWishlistReducer from "./features/whishlistSlice"
import currencySlice from './features/currencySlice'

import { setupListeners } from "@reduxjs/toolkit/query/react";
import { apiSlice } from './apiSlice';



export const store = configureStore({
    reducer: {
        // Add the generated reducer as a specific top-level slice
        [apiSlice.reducerPath]: apiSlice.reducer,
        counterCart:counterCartReducer,counterWishlist:counterWishlistReducer,counterCurrency:currencySlice
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: false
})


setupListeners(store.dispatch)

  
  // Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
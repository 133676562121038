import { privacyConstants } from "../../constants/index";

const Privacy = () => {
  return (
    <div className="bgcolorgold">
      <div className="wrapper flex flex-col gap-6">
        <h5 className="h5-bold">
          Privacy Policy for Chennapatnam Pattu Cheerala Angadi
        </h5>
        <div className="flex flex-col gap-2">
          <p className="p-semibold-20">Last updated: [Date]</p>
          <p className="p-regular-16">
            Thank you for choosing to be part of our community at Chennapatnam
            Pattu Cheerala Angadi. We are committed to protecting your personal
            information and your right to privacy. If you have any questions or
            concerns about our policy or our practices regarding your personal
            information, please contact us at [your email address].
          </p>
          <p className="p-regular-16">
            By visiting our website and purchasing our products, you agree to
            the terms of this privacy policy. This policy applies to all
            information collected through our website and other interactions
            with you.
          </p>
        </div>
        <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
            <p className="p-semibold-20">1. Information We Collect:</p>
            <div className="wrapper-weaves flex flex-col gap-1">
              <p>
                <strong>• Personal Information: </strong>When you make a
                purchase or create an account, we collect personal information
                such as your name, email address, shipping address, and payment
                details.
              </p>
              <p>
                <strong>• Device Information:</strong> We automatically collect
                certain information about your device, including information
                about your web browser, IP address, time zone, and some of the
                cookies that are installed on your device.
              </p>
              <p>
                <strong>• Order Information:</strong> When you make a purchase,
                we collect information about the products you ordered, payment
                information, and shipping details.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="p-semibold-20">2. How We Use Your Information:</p>
            <div className="wrapper-weaves flex flex-col gap-1">
              <p>
                <strong>• Processing Your Order:</strong>We use your personal information to process and fulfill your orders, including sending you order confirmations, tracking information, and invoices.
              </p>
              <p>
                <strong>• Communications: </strong> We may use your personal information to communicate with you about our products, promotions, and updates. You can opt-out of these communications at any time.
              </p>
              <p>
                <strong>• Improving Our Services: </strong> We use information about your device and how you interact with our website to improve our products and services.
              </p>
            </div>
          </div>
          <ol className="flex flex-col gap-3">
            {privacyConstants.map((section: any, index: number) => (
              <li key={index} className="flex flex-col gap-1">
                <h3 className="p-semibold-20">{section.title}</h3>
                    <p >{section.items}</p>
              </li>
            ))}
          </ol>
          <p className="p-regular-16 mt-4">
            Thank you for choosing{" "}
            <strong>Chennapatnam Pattu Cheerala Angadi</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;

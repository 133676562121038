import { SubNavigation } from "./NavItems";


const SubHeader = () => {
  return ( 
<header className="w-full hidden lg:block sticky top-0 z-50 text-white bg-opacity-0 2xl:pl-10">
  <div className="flex gap-2 ">
    <SubNavigation />
  </div>
</header>

  );
};

export default SubHeader;

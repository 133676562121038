import { IoLogoYoutube } from "react-icons/io";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { Separator } from "../../ui/separator";
import { FaLinkedin } from "react-icons/fa";
import { FaPinterestSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
import { letUsHelpYou, quickLinks, userPolicy } from "../../../constants";
import { useGetAllSubcategoryQuery } from "../../../redux/slice/CategoryApiSlice";

interface Subcategory {
  categoryId: number;
  id: number;
  subcategoryName: string;
}

const Footer = () => {
  const {data:AllSubCategories}=useGetAllSubcategoryQuery("subCategoriesList", {pollingInterval: 6000,refetchOnFocus: true,refetchOnMountOrArgChange: true,})
  
  //console.log(AllSubCategories);
  
  
  return (
    <footer className=" border-gray-500 border-t-2 mt-8 xl:px-2 bg-gray-200">
      <div className="wrapper lg:justify-between lg:flex-row flex-col flex  gap-10">
      {/* <div className="flex flex-col gap-2">
        <h1 className="text-md font-bold">QUICK LINKS</h1>
        {quickLinks.map(qlink=>{
          return <Link to={qlink} key={qlink} className="text-md font-normal">
            {qlink}
          </Link>
        })}
      </div> */}
      <div className="flex flex-col gap-2">
        <h1 className="text-md font-bold">USER POLICY</h1>
        {userPolicy.map((qlink:any)=>{
          return <Link to={qlink.route} key={qlink.name} className="text-md font-normal">
            {qlink.name}
          </Link>
        })}
      </div>
      <div className="flex flex-col gap-2">
        <h1 className="text-md font-bold">CONTACT US</h1>
        {letUsHelpYou.map((qlink:any)=>{
          return <Link to={qlink.path} key={qlink.name} className="text-md font-normal">
            {qlink.name}
          </Link>
        })}
      </div>
      <div className="flex flex-col  gap-2">
        <h1 className="text-md font-bold">SOCIAL MEDIA</h1>
        <div className="flex gap-4 ">
        <IoLogoYoutube size={28} color="red"/>
        <FaInstagram size={28} color="brown"/>
        {/* <FaFacebookF size={28} color="blue"/> */}
        <FaPinterestSquare size={28} color="red"/>
        </div>
      </div>
      </div>
      
       <div className="wrapper">
      <div className="flex flex-col gap-2">
        <h1 className="text-md font-bold">POPULAR SEARCHES</h1>
        <div className="flex flex-wrap items-center">
        {AllSubCategories?.result.length>0&&AllSubCategories?.result.map((subCat:Subcategory)=>{
          return<Link to={`/subcategory/${subCat.id}`} key={subCat.id} className="text-sm font-normal">
            {subCat.subcategoryName}{'|'}
          </Link> })}
        </div>
      </div>
      
      </div>
      <div className="wrapper flex justify-center  w-full">
        <div className="flex flex-col text-center gap-3 ">
        <h1 className="text-md font-bold">COMPLETELY SAFE AND SECURE PAYMENT METHOD</h1>
        <p className="text-sm font-semibold">We accept Netbanking, all major cards. We also accept orders with cash payments</p>
        <div className="flex gap-3 justify-center ">
        <img src="/assets/payments/paypal.png" alt="payment" width={150} className="h-[30px]" />
        <img src="/assets/payments/Razorpay.png" alt="payment" width={150} className="h-[30px]" />
      </div>
      </div>
      </div>
      <Separator className="border border-gray-400" />
      <div className=" wrapper  flex flex-center  p-5 text-center sm:flex-row">
       {/*  <Link to='/'>
          <img 
            src="/assets/logo/CPATitleLogoSmall.png"
            alt="logo"
            width={100}
            height={60}
          />
        </Link> */}
        
        <p>@copyright 2024 All Rights reserved.</p>
      </div>
    </footer>
  )
}

export default Footer
import { DataTable } from "../../components/shared/admin/components/Table/data-table";
import { useGetAllCustomerQuery } from "../../redux/slice/UserApiSlice";
import { userColumns } from "../../components/shared/admin/components/Customers/Columns";



const Customers = () => {
   const { isSuccess, isError, isLoading, data: getAllCustomers } = useGetAllCustomerQuery("usersList", {pollingInterval: 6000,refetchOnFocus: true,refetchOnMountOrArgChange: true,})

   let content
   if (isLoading) {
    content = (
        <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
    );
}
   if (isError) content = <p>{getAllCustomers?.result?.message}</p>
   if (!getAllCustomers?.result?.length){
     content= <p className="p-4 text-center text-lg font-semibold w-full">
       NO CUSTOMERS YET
     </p>}
   if (isSuccess&& getAllCustomers?.result?.length>0){
     content=  <>
     <div className="flex justify-between p-4">
        <h5 className='h5-bold'>CUSTOMERS</h5>
      </div>
      <div className=" p-4 rounded-md border-2 md:overflow-hidden shadow-lg bg-green-50">
        {getAllCustomers?.result?.length>0 && <DataTable data={getAllCustomers?.result} columns={userColumns}/>}
      </div>
     </>
   }
  return (<section className="md:ml-52">
    <main className=" border-2  bg-red-50  border-gray-500 md:mx-8 md:my-12 md:p-4">
      {content}
    </main>
    </section>)
}

export default Customers

